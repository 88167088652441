<template>
  <div>
    <a-modal v-model="visible" width="400px" :confirmLoading="loading" :maskClosable="false" @cancel="cancel" @ok="confirm">
      <div slot="title">{{form.id ? '编辑产品流程信息' : '新增产品流程信息' }}</div>
      <div>
        <a-form-model
          ref="form"
          :model="form"
          :rules="rules"
          :label-col="{ span: 4 }" 
          :wrapper-col="{ span: 16 }"
          >
        
          <a-form-model-item label="流程">
            <a-select v-model="form.process" :allowClear="true">
              <a-select-option
                v-for="item of processItems"
                :key="item.id"
                :value="item.id">{{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
            
          <a-form-model-item prop="remark" label="备注">
            <a-input v-model="form.remark" allowClear />
          </a-form-model-item>
        </a-form-model>
      </div>

    </a-modal>
  </div>
</template>

<script>
  import Cookies from 'js-cookie'
  import { ProductProcessCreate, ProductProcessUpdate } from '@/api/production'
  
  export default {
    name: 'FormModal',
    props: ['visible', 'form','processItems'],
    model: { prop: 'visible', event: 'cancel' },
    data() {
      return {
        Cookies,
        loading: false,
      };
    },
    methods: {

      confirm() {
        let formatData = {
          "id":this.form.id,
          "goods":this.form.goods,
          "process":this.form.process
        }
        console.log('formatData',formatData)
        this.$refs.form.validate(valid => {
          if (valid) {
            this.loading = true;
            let func = this.form.id ? ProductProcessUpdate : ProductProcessCreate;
            func(formatData).then(data => {
              this.$message.success(this.form.id ? '修改成功' : '新增成功');
              this.$emit('create');
              // this.$emit(this.form.id ? 'update' : 'create', data);
              this.cancel();
            }).finally(() => {
              this.loading = false;
            });
          }
        });
        
      },
      cancel() {
        console.log('点击了取消')
        this.$emit('cancel', false);
        this.$refs.form.resetFields();
      },
    },
  }
</script>

<style scoped>
</style>